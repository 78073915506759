import {Controller} from "@hotwired/stimulus"

export default class extends Controller {

    static targets = ["banner", "container", 'form', 'cookiesSelection', 'analyticsFrame'];

    connect() {
        let cookiesAcceptedAt = this.getCookie('hv_cookies_accepted_at');
        let acceptedCookies = this.getCookie('hv_cookies_accepted');
        if (cookiesAcceptedAt === null) {
            this.showCookieBanner();
        } else if (acceptedCookies === 'all' && this.hasAnalyticsFrameTarget) {
            // Load analytics if cookies were previously accepted
            this.loadAnalytics();
        }
    }

    accept(acceptedCookies) {
        const acceptedAt = new Date().toISOString();
        // Set browser cookie with 1 year expiry
        const expiryDate = new Date();
        expiryDate.setFullYear(expiryDate.getFullYear() + 1);
        document.cookie = `hv_cookies_accepted=${acceptedCookies};expires=${expiryDate.toUTCString()};path=/`;
        document.cookie = `hv_cookies_accepted_at=${acceptedAt};expires=${expiryDate.toUTCString()};path=/`;

        // Hide banner
        this.hideCookieBanner();

        // Load analytics if all cookies accepted
        if (acceptedCookies === 'all' && this.hasAnalyticsFrameTarget) {
            this.loadAnalytics();
        }
    }

    loadAnalytics() {
        // Get the frame URL
        const frameUrl = this.analyticsFrameTarget.getAttribute('src');
        
        // Fetch the analytics content
        fetch(frameUrl)
            .then(response => response.text())
            .then(html => {
                // Create a temporary container to parse the HTML
                const temp = document.createElement('div');
                temp.innerHTML = html;

                // Find the tracking scripts in the response
                const scripts = temp.querySelectorAll('script');
                
                // Append each script to the analytics frame
                scripts.forEach(script => {
                    const newScript = document.createElement('script');
                    
                    // Copy all attributes
                    Array.from(script.attributes).forEach(attr => {
                        newScript.setAttribute(attr.name, attr.value);
                    });
                    
                    // Copy the content
                    newScript.textContent = script.textContent;
                    
                    // Append to the frame
                    this.analyticsFrameTarget.appendChild(newScript);
                });
            })
            .catch(error => console.error('Error loading analytics:', error));
    }

    getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    }

    acceptEssential() {
        this.accept('essential');
    }

    acceptAll() {
        this.accept('all');
    }

    showCookieBanner() {
        this.containerTarget.style.display = "block";
        this.bannerTarget.style.display = "block";
    }

    hideCookieBanner() {
        this.bannerTarget.style.display = "none";
        this.containerTarget.style.display = "none";
    }
}
