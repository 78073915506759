import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["navbar", 'cta', 'hero']

    connect() {
        if (this.hasHeroTarget) {
            window.addEventListener('load', (event) => {
                this.toggle(event);
            });
            window.addEventListener('scroll', (event) => {
                this.toggle(event);
            });
        }
    }

    toggle(event) {
        if (this.heroOutOfViewport()) {
            this.navbarTarget.classList.remove('text-white');
            this.navbarTarget.classList.remove('bg-transparent');
            this.navbarTarget.classList.remove('bg-opacity-0');
            this.navbarTarget.classList.add('bg-opacity-80');
            this.navbarTarget.classList.add('text-black');
            this.navbarTarget.classList.add('border-b');
            this.navbarTarget.classList.add('sm:border');
            this.navbarTarget.classList.add('sm:border-gray-200');
            this.navbarTarget.classList.add('sm:border-1');
        } else {
            this.navbarTarget.classList.add('text-white');
            this.navbarTarget.classList.add('bg-transparent');
            this.navbarTarget.classList.add('bg-opacity-0');
            this.navbarTarget.classList.remove('text-black');
            this.navbarTarget.classList.remove('border-b');
            this.navbarTarget.classList.remove('sm:border');
            this.navbarTarget.classList.remove('sm:border-gray-200');
            this.navbarTarget.classList.remove('sm:border-1');
        }
    }

    heroOutOfViewport() {
        const rect = this.heroTarget.getBoundingClientRect();
        return rect.bottom <= 30;
    }
}