import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["trigger", "content", "icon"]

    connect() {
        // Set initial state
        this.contentTargets.forEach(content => content.classList.add('hidden'))
    }

    toggle(event) {
        const trigger = event.currentTarget
        const contentId = trigger.getAttribute('data-accordion-target')
        const content = document.querySelector(contentId)
        const icon = trigger.querySelector('[data-accordion-icon]')

        if (content) {
            const isExpanded = content.classList.toggle('hidden')
            
            // Update ARIA attributes
            trigger.setAttribute('aria-expanded', !isExpanded)
            
            // Rotate icon if present
            if (icon) {
                icon.style.transform = isExpanded ? 'rotate(180deg)' : 'rotate(0deg)'
            }
        }
    }
} 