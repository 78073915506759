import '@hotwired/turbo-rails'
import {
  Collapse,
  Drawer,
  Accordion,
  initFlowbite,
  Options
} from "flowbite";

import {application} from "./controllers/application"

// import TurboVisitorController from "./controllers/turbo_visitor_controller.js"
// application.register("turbo-visitor", TurboVisitorController)

import LandingNavbarController from "./controllers/tailwind/landing_navbar_controller.js"
application.register("landing-navbar", LandingNavbarController)

import LandingHeroController from "./controllers/tailwind/landing_hero_controller.js"
application.register("landing-hero", LandingHeroController)

import ProgressiveImageController from "./controllers/assets/progressive_image_controller"
application.register("progressive-image", ProgressiveImageController)

import CookieBannerController from "./controllers/cookie_banner_controller"
application.register("cookie-banner", CookieBannerController)

import Quotes__CalculatorController from "./controllers/quotes/calculator_controller"
application.register("quotes--calculator", Quotes__CalculatorController)

import Quotes__ClosingCostsController from "./controllers/quotes/closing_costs_controller"
application.register("quotes--closing-costs", Quotes__ClosingCostsController)

// import Quotes__MoneyInputController from "./controllers/quotes/money_input_controller"
// application.register("quotes--money-input", Quotes__MoneyInputController)

import Quotes__AccordionController from "./controllers/quotes/accordion_controller"
application.register("quotes--accordion", Quotes__AccordionController)

import DrawerController from "./controllers/drawer_controller"
application.register("hv-drawer", DrawerController)
