import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "form",
        "propertyPriceVisible", "propertyPriceHidden",
        "loanAmountVisible", "loanAmountHidden"
    ]
    static values = {
        maxLtv: { type: Number, default: 0.7 },
        currency: String,
        currencyPosition: String,
        debounce: { type: Number, default: 800 }
    }

    connect() {
        this.debounceTimeout = null
        this.isAdjusting = false
        this.isUpdating = false  // Add flag to track ongoing updates
        // Initialize formatted values
        this.formatVisibleInputs()
        // Track last submitted values
        this.lastSubmittedValues = this.getCurrentFormValues()
    }

    getCurrentFormValues() {
        return {
            property_price: this.propertyPriceHiddenTarget.value,
            loan_amount: this.loanAmountHiddenTarget.value,
            region_code: this.formTarget.querySelector('[name="region_code"]').value,
            sale_type: this.formTarget.querySelector('[name="sale_type"]').value
        }
    }

    hasChanges() {
        const currentValues = this.getCurrentFormValues()
        return Object.keys(currentValues).some(key => 
            currentValues[key] !== this.lastSubmittedValues[key]
        )
    }

    update(event) {
        if (event) {
            event.preventDefault()
        }
        
        if (!this.formTarget.checkValidity()) {
            return
        }

        // Skip update if no changes, unless it's from toggling optional costs
        const isOptionalToggle = event?.type === 'change' && 
            event.target.matches('input[name="optionals[]"]')
        if (!this.hasChanges() && !isOptionalToggle) {
            return
        }

        // Skip if already updating
        if (this.isUpdating) {
            return
        }

        this.isUpdating = true
        const formData = new FormData(this.formTarget)
        const method = this.formTarget.method.toUpperCase()

        if (method === 'GET') {
            const params = new URLSearchParams(formData)
            const url = `${this.formTarget.action}?${params}`
            fetch(url, {
                method: 'GET',
                headers: {
                    "Accept": "text/vnd.turbo-stream.html",
                    'X-Requested-With': 'XMLHttpRequest'
                }
            })
            .then(response => response.text())
            .then(html => {
                Turbo.renderStreamMessage(html)
                // Update last submitted values after successful submission
                this.lastSubmittedValues = this.getCurrentFormValues()
                this.isUpdating = false
            })
            .catch(() => {
                this.isUpdating = false
            })
        }
    }

    // Input handling
    handleInput(event) {
        const inputName = event.params.input
        const value = this.parseInputValue(event.target.value)
        
        // Update visible formatting immediately
        this.formatNumber(value, inputName)

        // Debounce the actual value update
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout)
        }
        
        this.debounceTimeout = setTimeout(() => {
            this.updateValue(value, inputName)
        }, this.debounceValue)
    }

    handleBlur(event) {
        const inputName = event.params.input
        const value = this.parseInputValue(event.target.value)
        
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout)
            // Only update value if there's a pending debounced change
            this.updateValue(value, inputName, false)
        }
    }

    // Value updates and validation
    updateValue(value, inputName, skipValidation = false) {
        if (this.isAdjusting) return  // Prevent recursive updates
        
        // Update hidden input
        this.getHiddenInput(inputName).value = value || ''
        // Update visible input formatting
        this.formatNumber(value, inputName)
        
        if (!skipValidation && this.validateLoanAmount(inputName)) {
            // Only update if there are actual changes
            if (this.hasChanges()) {
                this.update()
            }
        }
    }

    validateLoanAmount(changedInput) {
        const propertyPrice = parseInt(this.propertyPriceHiddenTarget.value) || 0
        const loanAmount = parseInt(this.loanAmountHiddenTarget.value) || 0
        
        if (propertyPrice > 0 && loanAmount > 0) {
            const maxLoanAmount = Math.floor(propertyPrice * this.maxLtvValue)
            const minPropertyPrice = Math.ceil(loanAmount / this.maxLtvValue)

            if (loanAmount > maxLoanAmount) {
                if (changedInput === 'property_price') {
                    // Update both inputs directly
                    this.getHiddenInput('loan_amount').value = maxLoanAmount
                    this.formatNumber(maxLoanAmount, 'loan_amount')
                    this.update()
                    return false
                } else {
                    // Update both inputs directly
                    this.getHiddenInput('property_price').value = minPropertyPrice
                    this.formatNumber(minPropertyPrice, 'property_price')
                    this.update()
                    return false
                }
            }
        }
        return true
    }

    // Formatting helpers
    formatVisibleInputs() {
        const propertyPrice = parseInt(this.propertyPriceHiddenTarget.value) || 0
        const loanAmount = parseInt(this.loanAmountHiddenTarget.value) || 0
        
        this.formatNumber(propertyPrice, 'property_price')
        this.formatNumber(loanAmount, 'loan_amount')
    }

    formatNumber(value, inputName) {
        let formattedValue = null
        if (!isNaN(value) && value !== null) {
            formattedValue = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
            
            if (this.currencyPositionValue === 'prefix') {
                formattedValue = `${this.currencyValue} ${formattedValue}`
            } else {
                formattedValue = `${formattedValue} ${this.currencyValue}`
            }
        }
        this.getVisibleInput(inputName).value = formattedValue
    }

    // Input helpers
    getVisibleInput(inputName) {
        return inputName === 'property_price' ? 
            this.propertyPriceVisibleTarget : 
            this.loanAmountVisibleTarget
    }

    getHiddenInput(inputName) {
        return inputName === 'property_price' ? 
            this.propertyPriceHiddenTarget : 
            this.loanAmountHiddenTarget
    }

    // Button actions
    clearInput(event) {
        const inputName = event.params.input
        this.updateValue(null, inputName)
    }

    incrementInput(event) {
        const inputName = event.params.input
        const currentValue = parseInt(this.getHiddenInput(inputName).value) || 0
        const increment = this.getIncrement(currentValue)
        this.updateValue(currentValue + increment, inputName)
    }

    decrementInput(event) {
        const inputName = event.params.input
        const currentValue = parseInt(this.getHiddenInput(inputName).value) || 0
        const increment = this.getIncrement(currentValue)
        this.updateValue(Math.max(0, currentValue - increment), inputName)
    }

    // Utility methods
    parseInputValue(value) {
        const valueStr = value.split('.')[0]
        return valueStr ? parseInt(valueStr.replace(/[^\d]/g, '')) : null
    }

    getIncrement(currentValue) {
        if (currentValue >= 1000000) return 100000
        if (currentValue >= 500000) return 50000
        if (currentValue >= 100000) return 10000
        if (currentValue >= 10000) return 5000
        return 1000
    }

    validateNumericInput(event) {
        const allowedKeys = [
            'Backspace', 'Delete', 'Tab', 'Enter', 'Escape', '.', 'ArrowLeft', 'ArrowRight',
            'Home', 'End', 'Meta', 'Control', 'c', 'v', 'x', 'a'
        ]

        if ((event.metaKey || event.ctrlKey) && ['c', 'v', 'x', 'a'].includes(event.key)) {
            return
        }

        if (allowedKeys.includes(event.key)) {
            return
        }

        if (/^\d$/.test(event.key)) {
            return
        }

        event.preventDefault()
    }

    toggleOptional(event) {
        this.update(event);
    }
} 