import { Controller } from "@hotwired/stimulus"
import { Drawer } from 'flowbite'

export default class extends Controller {
  static targets = ["content"]

  connect() {
    // Wait for next tick to ensure DOM is ready
    requestAnimationFrame(() => {
      this.initializeDrawer()
    })
  }

  initializeDrawer() {
    // Initialize the drawer
    const drawerElement = this.contentTarget
    if (!drawerElement) {
      console.warn('Drawer content target not found')
      return
    }

    this.drawerInstance = new Drawer(drawerElement, {
      placement: 'right',
      backdrop: true,
      bodyScrolling: false,
      edge: false,
      edgeOffset: '',
      backdropClasses: 'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-[105]'
    })

    // Ensure drawer starts hidden
    this.drawerInstance.hide()

    // Close drawer on escape key
    document.addEventListener('keydown', this.handleEscape)
  }

  disconnect() {
    // Clean up when controller is disconnected
    if (this.drawerInstance) {
      this.drawerInstance.hide()
    }
    document.removeEventListener('keydown', this.handleEscape)
  }

  handleEscape = (e) => {
    if (e.key === 'Escape' && this.isOpen) {
      this.close()
    }
  }

  toggle() {
    if (this.drawerInstance) {
      this.drawerInstance.toggle()
    }
  }

  close() {
    if (this.drawerInstance) {
      this.drawerInstance.hide()
    }
  }

  get isOpen() {
    return this.drawerInstance?.isVisible() || false
  }
} 