import {Controller} from "@hotwired/stimulus";
import {Turbo} from "@hotwired/turbo-rails";

export default class extends Controller {
    static targets = [
        "currencySymbol",
        "prefixSymbol",
        "suffixSymbol",
        "form",
        "focusedOutcome",
        "submitButton",
        "outcomeContainer",
        "likelyOutcome",
        "stretchOutcome",
        // Money stream targets
        "salaryCheck", "salaryInput",
        "independentCheck", "independentInput",
        "otherCheck", "otherInput",
        "rent_or_mortgageCheck", "rent_or_mortgageInput",
        "other_commitmentsCheck", "other_commitmentsInput"
    ]

    static values = {
        formState: Object,
        step: {type: Number, default: 0.1}
    }

    connect() {
        if (this.hasFormTarget) {
            this.timeout = null
            this.isComputing = false
            this.isLoading = false
            this.saveInitialState()
            this.updateSubmitButton()
            this.setupTurboListeners()
            if (this.hasAllMoneyStreamTargets()) {
                this.initializeMoneyStreams()
            }
        }
    }

    setupTurboListeners() {
        // Listen for frame start loading
        this.element.addEventListener("turbo:before-frame-render", (event) => {
            if (this.isLoading) {
                const elapsedTime = Date.now() - this.loadingStartTime
                const remainingTime = Math.max(0, 270 - elapsedTime)

                if (remainingTime > 0) {
                    event.preventDefault()
                    setTimeout(() => {
                        this.isLoading = false
                        event.detail.resume()
                    }, remainingTime)
                }
            }
        })

        // Listen for frame finish loading
        this.element.addEventListener("turbo:frame-render", () => {
            this.isLoading = false
            this.outcomeContainerTarget.classList.remove('opacity-50', 'pointer-events-none')
            this.submitButtonTarget.innerHTML = '<i class="fa-light fa-calculator me-2"></i>Calculate'
            this.updateSubmitButton()
        })

        // Listen for frame load error
        this.element.addEventListener("turbo:frame-load-error", () => {
            this.isLoading = false
            this.outcomeContainerTarget.classList.remove('opacity-50', 'pointer-events-none')
            this.submitButtonTarget.innerHTML = '<i class="fa-light fa-calculator me-2"></i>Calculate'
            this.updateSubmitButton()
        })
    }

    saveInitialState() {
        this.formStateValue = this.getCurrentFormState()
    }

    getCurrentFormState() {
        const form = this.formTarget
        return {
            term: form.querySelector('[name="qt[term]"]').value,
            currencyCode: form.querySelector('[name="qt[currency_code]"]').value,
            likelyInterest: form.querySelector('[name="qt[likely_interest]"]')?.value,
            stretchInterest: form.querySelector('[name="qt[stretch_interest]"]')?.value,
            likelyDti: form.querySelector('[name="qt[likely_dti]"]')?.value,
            stretchDti: form.querySelector('[name="qt[stretch_dti]"]')?.value,
            likelyEquity: form.querySelector('[name="qt[likely_equity]"]')?.value,
            stretchEquity: form.querySelector('[name="qt[stretch_equity]"]')?.value,
            incomes: this.getStreamState('salary', 'independent', 'other'),
            expenses: this.getStreamState('rent_or_mortgage', 'other_commitments')
        }
    }

    getStreamState(...keys) {
        const state = {}
        keys.forEach(key => {
            const amount = this.formTarget.querySelector(`[name="qt[${key}_amount]"]`)?.value || ''
            const enabled = this.formTarget.querySelector(`[name="qt[${key}_enabled]"]`)?.checked || false
            state[key] = {amount, enabled}
        })
        return state
    }

    hasSignificantChanges() {
        const currentState = this.getCurrentFormState()

        // Check term and currency
        if (currentState.term !== this.formStateValue.term) return true
        if (currentState.currencyCode !== this.formStateValue.currencyCode) return true

        // Check interest rates
        const currentLikely = parseFloat(currentState.likelyInterest).toFixed(1)
        const initialLikely = parseFloat(this.formStateValue.likelyInterest).toFixed(1)
        const currentStretch = parseFloat(currentState.stretchInterest).toFixed(1)
        const initialStretch = parseFloat(this.formStateValue.stretchInterest).toFixed(1)

        // Check DTI rates
        const currentLikelyDti = parseFloat(currentState.likelyDti).toFixed(0)
        const initialLikelyDti = parseFloat(this.formStateValue.likelyDti).toFixed(0)
        const currentLikelyEquity = parseFloat(currentState.likelyEquity).toFixed(0)
        const initialLikelyEquity = parseFloat(this.formStateValue.likelyEquity).toFixed(0)

        const currentStretchDti = parseFloat(currentState.stretchDti).toFixed(0)
        const initialStretchDti = parseFloat(this.formStateValue.stretchDti).toFixed(0)
        const currentStretchEquity = parseFloat(currentState.stretchEquity).toFixed(0)
        const initialStretchEquity = parseFloat(this.formStateValue.stretchEquity).toFixed(0)

        if (currentLikely !== initialLikely ||
            currentStretch !== initialStretch ||
            currentLikelyDti !== initialLikelyDti ||
            currentStretchDti !== initialStretchDti ||
            currentLikelyEquity !== initialLikelyEquity ||
            currentStretchEquity !== initialStretchEquity
        ) {
            this.formStateValue = currentState
            return true
        }

        // Check incomes and expenses for numeric changes
        const checkStream = (current, initial) => {
            return Object.keys(current).some(key => {
                const currentItem = current[key]
                const initialItem = initial[key]

                // Check for enabled state changes or amount changes
                return (currentItem.enabled !== initialItem.enabled && parseFloat(currentItem.amount || 0) > 0) ||
                    (currentItem.enabled &&
                        currentItem.amount !== '' &&
                        parseFloat(currentItem.amount) !== parseFloat(initialItem.amount))
            })
        }

        const hasStreamChanges = checkStream(currentState.incomes, this.formStateValue.incomes) ||
            checkStream(currentState.expenses, this.formStateValue.expenses)

        if (hasStreamChanges) {
            this.formStateValue = currentState
        }

        return hasStreamChanges
    }

    currencyChanged(event) {
        const option = event.target.selectedOptions[0]
        const symbol = option.dataset.currencySymbol
        const isPostfixed = option.dataset.postfixed === 'true'

        // Update all currency symbol pairs in the form
        this.prefixSymbolTargets.forEach((prefix, index) => {
            const suffix = this.suffixSymbolTargets[index]
            prefix.textContent = symbol
            suffix.textContent = symbol
            prefix.classList.toggle('hidden', isPostfixed)
            suffix.classList.toggle('hidden', !isPostfixed)
        })

        this.debouncedCompute()
    }

    compute() {
        if (this.isComputing || this.isLoading) return

        this.isComputing = true
        setTimeout(() => {
            if (this.hasSignificantChanges()) {
                this.debouncedCompute()
            }
            this.updateSubmitButton()
            this.isComputing = false
        }, 0)
    }

    debouncedCompute() {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(() => {
            if (!this.isLoading) {
                this.submitForm()
            }
        }, 850)
    }

    submitForm() {
        if (this.isLoading) return

        this.isLoading = true
        this.loadingStartTime = Date.now()

        // Determine what needs to be updated
        const currentState = this.getCurrentFormState()
        const changes = this.getChangeType(currentState)

        // Build URL with query parameters
        const formData = new FormData(this.formTarget)
        formData.append('update_type', changes.type)
        if (changes.outcome) {
            formData.append('outcome', changes.outcome)
        }
        const queryParams = new URLSearchParams(formData).toString()
        const url = `${this.formTarget.action}?${queryParams}`

        // Add loading state with transition
        const affectedElements = changes.type === 'amounts'
            ? [
                this.likelyOutcomeTarget.querySelector('[id$="_amounts"]'),
                this.stretchOutcomeTarget.querySelector('[id$="_amounts"]')
            ]
            : [
                (changes.outcome === 'likely' ? this.likelyOutcomeTarget : this.stretchOutcomeTarget)
                    .querySelector('[id$="_amounts"]')
            ]

        affectedElements.forEach(el => {
            el.style.transition = 'opacity 150ms ease-in-out'
            el.classList.add('opacity-50')
        })

        // Send as Turbo Stream request
        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'text/vnd.turbo-stream.html',
                'X-Requested-With': 'XMLHttpRequest'
            }
        })
            .then(response => response.text())
            .then(html => {
                // First render the new content
                Turbo.renderStreamMessage(html)

                // Then add highlight animation to the updated amounts
                affectedElements.forEach(el => {
                    const amountElements = el.querySelectorAll('.font-black > span:last-child')
                    amountElements.forEach(amount => {
                        // Remove any existing animation
                        amount.classList.remove('highlight-update')
                        // Force a reflow to restart the animation
                        void amount.offsetWidth
                        amount.classList.add('highlight-update')
                        // Remove the class after animation completes
                        setTimeout(() => {
                            amount.classList.remove('highlight-update')
                        }, 800) // Match this with CSS animation duration
                    })
                })

                // Small delay before removing loading state to ensure smooth transition
                setTimeout(() => {
                    affectedElements.forEach(el => {
                        el.classList.remove('opacity-50')
                        // Remove transition after animation completes
                        setTimeout(() => {
                            el.style.transition = ''
                        }, 150)
                    })
                }, 50)

                this.isLoading = false
            })
            .catch(error => {
                console.error('Error:', error)
                this.isLoading = false

                // Remove loading state and transition on error
                affectedElements.forEach(el => {
                    el.classList.remove('opacity-50')
                    el.style.transition = ''
                })
            })
    }

    getChangeType(currentState) {
        // Check if income/expense/term/currency changed
        if (currentState.term !== this.formStateValue.term ||
            currentState.currencyCode !== this.formStateValue.currencyCode ||
            this.checkStreamChanges(currentState)) {
            return {type: 'amounts'}
        }

        // Check which outcome changed (DTI or interest rate)
        if (currentState.likelyDti !== this.formStateValue.likelyDti ||
            currentState.likelyInterest !== this.formStateValue.likelyInterest) {
            return {type: 'single', outcome: 'likely'}
        }

        if (currentState.stretchDti !== this.formStateValue.stretchDti ||
            currentState.stretchInterest !== this.formStateValue.stretchInterest) {
            return {type: 'single', outcome: 'stretch'}
        }

        return {type: 'amounts'}
    }

    checkStreamChanges(currentState) {
        const checkStream = (current, initial) => {
            return Object.keys(current).some(key => {
                const currentItem = current[key]
                const initialItem = initial[key]
                return (currentItem.enabled !== initialItem.enabled && parseFloat(currentItem.amount || 0) > 0) ||
                    (currentItem.enabled &&
                        currentItem.amount !== '' &&
                        parseFloat(currentItem.amount) !== parseFloat(initialItem.amount))
            })
        }

        return checkStream(currentState.incomes, this.formStateValue.incomes) ||
            checkStream(currentState.expenses, this.formStateValue.expenses)
    }

    updateSubmitButton() {
        const hasChanges = this.hasSignificantChanges()
        this.submitButtonTarget.disabled = !hasChanges
        this.submitButtonTarget.classList.toggle('opacity-50', !hasChanges)
        this.submitButtonTarget.classList.toggle('cursor-not-allowed', !hasChanges)
    }

    // Interest rate controls
    incrementRate(event) {
        const input = event.target.closest('.flex').querySelector('input')
        const currentValue = parseFloat(input.value)
        const step = parseFloat(input.step)
        const max = parseFloat(input.max)

        const newValue = Math.min(max, currentValue + step)
        // Format based on input type
        input.value = this.formatNumericValue(newValue, input.name)
        this.compute()
    }

    decrementRate(event) {
        const input = event.target.closest('.flex').querySelector('input')
        const currentValue = parseFloat(input.value)
        const step = parseFloat(input.step)
        const min = parseFloat(input.min)

        const newValue = Math.max(min, currentValue - step)
        // Format based on input type
        input.value = this.formatNumericValue(newValue, input.name)
        this.compute()
    }

    formatNumericValue(value, inputName) {
        if (inputName.includes('interest')) {
            // Format interest rate with one decimal place
            return value.toFixed(1)
        } else {
            // Format other numeric values as integers
            return Math.round(value).toString()
        }
    }

    // Optional: Add input formatting on blur
    formatOnBlur(event) {
        const input = event.target
        const value = parseFloat(input.value)
        if (!isNaN(value)) {
            input.value = this.formatNumericValue(value, input.name)
        }
    }

    // Money stream handling
    initializeMoneyStreams() {
        this.moneyStreamPairs.forEach(([check, input]) => {
            this.handleStreamState(check, input)
        })
    }

    get moneyStreamPairs() {
        return [
            [this.salaryCheckTarget, this.salaryInputTarget],
            [this.independentCheckTarget, this.independentInputTarget],
            [this.otherCheckTarget, this.otherInputTarget],
            [this.rent_or_mortgageCheckTarget, this.rent_or_mortgageInputTarget],
            [this.other_commitmentsCheckTarget, this.other_commitmentsInputTarget]
        ]
    }

    toggleStreamOn(event) {
        // Find the nearest ancestor with data-streamid
        const streamContainer = event.target.closest('[data-streamid]')
        if (!streamContainer) return

        const streamId = streamContainer.dataset.streamid
        const checkbox = this[`${streamId}CheckTarget`]
        const input = this[`${streamId}InputTarget`]

        if (!checkbox.checked) {
            checkbox.checked = true
            // First enable the input
            this.handleStreamState(checkbox, input, true)
        }
    }

    handleStreamToggle(event) {
        const streamId = event.target.id.replace('_enabled', '')
        const input = this[`${streamId}InputTarget`]

        this.handleStreamState(event.target, input)
    }

    resizeInput(event) {
        let input = event.target;
        // Update input width based on content
        const length = input.value.length || 1
        input.style.width = `${Math.max(length + 1, 3)}ch`
    }

    handleStreamState(checkbox, input, shouldFocus = false) {
        if (!checkbox || !input) return

        const isEnabled = checkbox.checked
        input.readOnly = !isEnabled

        // Update input width based on content
        const length = input.value.length || 1
        input.style.width = `${Math.max(length + 1, 3)}ch`

        // Update input styling based on enabled state
        input.classList.toggle('font-bold', isEnabled)
        input.classList.toggle('text-dark-800', isEnabled)
        input.classList.toggle('line-through', !isEnabled)

        // Find and update both currency symbols
        const container = input.closest('[data-streamid]')
        const prefixSymbol = container.querySelector('[data-quotes--calculator-target="prefixSymbol"]')
        const suffixSymbol = container.querySelector('[data-quotes--calculator-target="suffixSymbol"]')

        ;[prefixSymbol, suffixSymbol].forEach(symbol => {
            if (symbol) {
                symbol.classList.toggle('font-bold', isEnabled)
                symbol.classList.toggle('text-dark-800', isEnabled)
                symbol.classList.toggle('line-through', !isEnabled)
            }
        })

        const amount = parseFloat(input.value) || 0
        if (amount > 0 && !shouldFocus) {
            this.submitForm()
        } else if (isEnabled && shouldFocus) {
            setTimeout(() => {
                input.focus()
                input.select()
            }, 10)
        }
    }

    hasAllMoneyStreamTargets() {
        try {
            this.moneyStreamPairs.forEach(([check, input]) => {
                if (!check || !input) throw new Error('Missing target')
            })
            return true
        } catch (e) {
            console.warn('Some money stream targets are not available')
            return false
        }
    }

    // Add a new method to handle input events
    initialize() {
        if (this.hasFormTarget) {
            super.initialize()

            // Add input event listeners to all money inputs
            this.moneyStreamPairs.forEach(([_, input]) => {
                input.addEventListener('input', this.handleInputResize.bind(this))
            })
        }
    }

    handleInputResize(event) {
        const input = event.target
        const length = input.value.length || 1
        input.style.width = `${Math.max(length + 1, 3)}ch`
    }

    showStretch() {
        this.focusedOutcomeTarget.value = 'stretch';
        this.likelyOutcomeTarget.classList.add('opacity-0', 'pointer-events-none')
        this.stretchOutcomeTarget.classList.remove('opacity-0', 'pointer-events-none')
    }

    showLikely() {
        this.focusedOutcomeTarget.value = 'likely'
        this.stretchOutcomeTarget.classList.add('opacity-0', 'pointer-events-none')
        this.likelyOutcomeTarget.classList.remove('opacity-0', 'pointer-events-none')
    }

    validateNumericInput(event) {
        // Allow: backspace, delete, tab, escape, enter, decimal point
        const allowedKeys = [
            'Backspace', 'Delete', 'Tab', 'Enter', 'Escape', '.', 'ArrowLeft', 'ArrowRight'
        ];

        // Allow decimal point only if it doesn't exist yet
        if (event.key === '.' && event.target.value.includes('.')) {
            event.preventDefault();
            return;
        }

        // Allow the keys in allowedKeys array
        if (allowedKeys.includes(event.key)) {
            return;
        }

        // Allow numbers
        if (/^\d$/.test(event.key)) {
            // For interest rate inputs (max 2 decimal places)
            if (event.target.name.includes('interest')) {
                const parts = event.target.value.split('.');
                if (parts[1] && parts[1].length >= 1) {
                    event.preventDefault();
                    return;
                }
            }
            return;
        }

        // Prevent any other input
        event.preventDefault();
    }
}